import React, { Component } from 'react';
import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles: StyleRulesCallback = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
});


interface Props {
	classes: any,
	open: boolean,
	onClose: () => void,
	onAccept: () => void
}

interface State {
}


class CopyCampaignDialog extends Component<Props, State> {
	state: Readonly<State> = {
		
	}

	save = () => {
		this.props.onClose()
	}

	render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Copy this item??"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
							Are you sure you want to copy this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose} color="primary">
              Disagree
            </Button>
            <Button onClick={this.props.onAccept} color="secondary" autoFocus>
              Copy
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(CopyCampaignDialog);

