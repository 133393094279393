import React, { Component } from 'react';
import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import {
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Message,
  Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "../../data/firebase"
import { Typography } from '@material-ui/core';

const styles: StyleRulesCallback = (theme) => ({
  center: {
		display: 'flex', 
		justifyContent: 'center'
  },
});

interface Props {
	classes: any
}

interface Props {
	classes: any
}

interface State {
	email: string,
	password: string,
	errors: Array<any>,
	loading: boolean
}

class Login extends React.Component<Props, State> {

	state: Readonly<State> = {
		email: "",
    password: "",
    errors: [],
    loading: false
	};

  displayErrors = (errors: any) =>
    errors.map((error: any, i: any) => <p key={i}>{error.message}</p>);

  handleChangePassword = (event: any) => {
    this.setState({ password: event.target.value });
	};
	
	handleChangeEmail = (event: any) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = (event: any)  => {
    event.preventDefault();
    if (this.isFormValid(this.state.email, this.state.password)) {
      this.setState({ errors: [], loading: true });
      firebase
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(signedInUser => {
          console.log(signedInUser);
        })
        .catch(err => {
          console.error(err);
          this.setState({
            errors: this.state.errors.concat(err),
            loading: false
          });
        });
    }
  };

  isFormValid = (email: string, password: string) => email && password;

  handleInputError = (errors: any, inputName: any) => {
    return errors.some((error: any) => error.message.toLowerCase().includes(inputName))
      ? "error"
      : "";
  };

  render() {
    const { email, password, errors, loading } = this.state;

    return (
      <Grid textAlign="center" verticalAlign="middle" className="app">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h1" icon color="violet" textAlign="center">
            <Icon name="code branch" color="violet" />
            Login
          </Header>
          <Form onSubmit={this.handleSubmit} size="large">
            <Segment stacked>
              <Form.Input
                fluid
                name="email"
                icon="mail"
                iconPosition="left"
                placeholder="Email Address"
                onChange={this.handleChangeEmail}
                value={email}
                className={this.handleInputError(errors, "email")}
                type="email"
              />

              <Form.Input
                fluid
                name="password"
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                onChange={this.handleChangePassword}
                value={password}
                className={this.handleInputError(errors, "password")}
                type="password"
              />

              <Button
                disabled={loading}
                className={loading ? "loading" : ""}
                color="violet"
                fluid
                size="large"
              >
                Submit
              </Button>
            </Segment>
          </Form>
          {errors.length > 0 && (
            <Message error>
              <h3>Error</h3>
              {this.displayErrors(errors)}
            </Message>
          )}
          <Message>
            Don't have an account? <Link to="/register">Register</Link>
          </Message>
          <br/>
          <br/>
          <a target="_blank" href="/policy.html">Privacy Policy</a>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);