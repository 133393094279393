import User from "./model";

export default interface UserState {
	currentUser: User | null,
	isLoading: boolean
}

export const initialUserState: UserState = {
  currentUser: null,
  isLoading: true
}