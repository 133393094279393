import { createStore, applyMiddleware, AnyAction } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { compose } from 'redux';

import rootReducer from './reducer';
import epics from './epics'
import AppState, { initialAppState } from './state';
import { Action } from './actions';
import { Services } from '../services/types';
import { composeWithDevTools } from 'redux-devtools-extension';
import services from '../services/index'


export const epicMiddleware = createEpicMiddleware<Action, Action, AppState, Services>({
  dependencies: services
});
function configureStore(initialState?: object) {
  // configure middlewares
  const middlewares = [epicMiddleware];
  // compose enhancers
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  // create store
	const store = createStore(rootReducer, initialState!, enhancer);
	
	epicMiddleware.run(epics);
	return store
}

const store = configureStore(initialAppState);

export default store;