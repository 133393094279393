import React, { Component } from 'react';
import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Dropzone from 'react-dropzone';
import { TextField, FormControlLabel, Checkbox, FormGroup, Badge } from '@material-ui/core';
import Campaign, { CampaignOptional } from '../../data/campaign/model';
import FavoriteIcon from '@material-ui/icons/Favorite';
import grey from '@material-ui/core/colors/grey';
import CopyCampaignDialog from './CopyCampaignDialog';
import { withSnackbar, InjectedNotistackProps } from 'notistack';
import 'date-fns';
import {  DatePicker } from 'material-ui-pickers';
import { storage } from '../../data/firebase'
import firebase from 'firebase';

function toString(value: any): string {
	if (!value || typeof value !== "string") {
		return ''
	}
	return value as string
}

function toFloat(value: any): number {
	if (isNaN(value)) {
		return 0
	}
	return parseFloat(value)
}

function toInt(value: any): number {
	if (isNaN(value)) {
		return 0
	}
	return parseInt(value)
}

function toBoolean(value: any, defaultValue: boolean = false): boolean {
	if (!value || typeof value !== "boolean") {
		return defaultValue
	}
	return value as boolean
}


const styles: StyleRulesCallback = (theme) => ({
	root: {
    flexGrow: 1,
  },
  appBar: {
    position: 'fixed',
  },
  flex: {
    flex: 1,
	},
	flexRow: {
		flex: 1,
		flexDirection: 'row'
	},
	image: {
		'max-height': '130px',
		'max-width': '200px',
		height: 'auto',
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2
	},
	badgeFav: {
		marginRight: theme.spacing.unit * 4
	},
	dropZone: {
		'background-color': '#f1f1f1',
		width: '100%',
		height: '140px',
		'min-height': '100px !important',
		display: 'flex',
    alignItems: 'center',
		justifyContent: 'center',
		marginLeft: theme.spacing.unit * 2,
		padding: 10
	},
	list: {
		marginTop: theme.spacing.unit * 4,
		height: '100% !important'
	},
	formContainer: {
		display: 'flex',
		width: '100%',
    flexWrap: 'wrap',
	},
	textField20: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '30%',
	},
	textField30: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '30%',
	},
	textField40: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '30%',
	},
	textField50: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '50%',
	},
	textField80: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '80%',
	},
	textField100: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
	},
	textFieldSmall: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
	},
	textFieldMedium: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
	},
	textFieldLarge: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 300,
	},
	dense: {
    marginTop: 19,
	},
	switchControl: {
		marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
	},
	iconHover: {
		color: grey[100],
    '&:hover': {
      color: grey[100],
    },
  },
});

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

interface Props extends InjectedNotistackProps {
	classes: any,
	open: boolean,
	onClose: () => void,
	campaign?: Campaign | CampaignOptional
}

interface State extends CampaignOptional {
	imageFile?: File,
	openCopyCampaignDialog?: boolean,
	selectedDate?: Date,
	errors?: Array<String>
	inSavingState?: boolean
}

class EditCampaignDialog extends Component<Props, State> {
	state: Readonly<State> = {
		...(this.props.campaign ? this.props.campaign : {}),
		imageFile: undefined,
		openCopyCampaignDialog: false,
		selectedDate: new Date((this.props.campaign && this.props.campaign.expiry_date) || 0),
		inSavingState: false
	}
	
	handleSave = () => {
		this.setState({inSavingState: true})

		if (!this.virifyData()) {
			this.props.enqueueSnackbar('Invalid data!', { variant: 'error' });
			this.setState({inSavingState: false})
			return
		} 
		if (this.state.imageFile) {
			this.uploadImageFile()
		} else {
			this.saveCampaign()
		}
		

	}

	saveCampaign = () =>  {
		const c: CampaignOptional = this.state

		const campaign: {} = {
			title: toString(c.title),
			details: toString(c.details),
			image_url: toString(c.image_url),
			image_height: toInt(c.image_height),
			image_width: toInt(c.image_width),
			pid: toInt(c.pid),
			type: toString(c.type),
			banner: toString(c.banner),
			callback_code: toString(c.callback_code),
			categories: c.categories || [],
			coupon: toString(c.coupon),
			daily_cap: toInt(c.daily_cap),
			daily_cap_unit: toString(c.daily_cap_unit),
			discount: toInt(c.discount),
			discount_unit: toString(c.discount_unit),
			email: toString(c.email),
			email_link: toString(c.email_link),
			epc: toFloat(c.epc),
			expiry_date: toInt(c.expiry_date),
			like_counter: toInt(c.like_counter),
			mobile_link: toString(c.mobile_link),
			native_link: toString(c.native_link),
			optimized: toBoolean(c.optimized),
			provider: toString(c.provider),
			preview: toString(c.preview),
			price: toFloat(c.price),
			rate: toFloat(c.rate),
			rate_unit: toString(c.rate_unit),
			sale: toBoolean(c.sale),
			social_link: toString(c.social_link),
			visible: toBoolean(c.sale, true),
		}

		const campaignRef = firebase.firestore().collection("campaigns")
		if (c.id) {
			campaignRef.doc(c.id).set(campaign)
				.then(this.saveCampaignSuccess)
				.catch(this.saveCampaignError)
		} else {
			campaignRef.add(campaign)
				.then(this.saveCampaignSuccess)
				.catch(this.saveCampaignError)
		}
	}

	saveCampaignSuccess = () => {
		this.setState({inSavingState: false})
		this.props.enqueueSnackbar('Campaign saved!', { variant: 'success' });
		this.props.onClose()
	}

	saveCampaignError = (error: any) => {
		console.error(error)
		this.setState({inSavingState: false})
		this.props.enqueueSnackbar('Campaign saving error!', { variant: 'error' });
	}



	//createValidCampaign = (): Campaign | null => {
	virifyData = (): boolean => {
		const errors: Array<string> = []
		const { imageFile, image_url } = this.state

		this.checkFieldStringNotEmpty('title', errors)
		this.checkFieldStringNotEmpty('details', errors)

		if (!imageFile && (!image_url || image_url.length === 0)) {
			errors.push('image_url')
		}
		this.checkFieldStringNotEmpty('type', errors)
		this.checkFieldInteger('pid', errors)
		this.checkFieldStringNotEmpty('provider', errors)
		this.checkFieldStringNotEmpty('native_link', errors)
		this.checkFieldFloat('price', errors)
		this.checkFieldInteger('discount', errors)
		this.checkFieldStringNotEmpty('discount_unit', errors)
		this.checkFieldFloat('rate', errors)
		this.checkFieldStringNotEmpty('rate_unit', errors)
		this.checkFieldInteger('daily_cap', errors)
		this.checkFieldStringNotEmpty('daily_cap_unit', errors)
		this.checkFieldFloat('epc', errors)

		if (errors.length > 0) {
			this.setState({errors})
			return false
		} else {
			this.setState({errors: undefined})
			return true
		}
	}

	checkFieldInteger = <K extends keyof State>(key: K, errors: Array<string>) => {
		const value: any = this.state[key]
		const intValue: number | null = !isNaN(value) ? parseFloat(value) : null
		if (intValue === null || !Number.isInteger(intValue)) {
			errors.push(key)
		}
	}

	checkFieldFloat= <K extends keyof State>(key: K, errors: Array<string>) => {
		const value: any = this.state[key]

		const intValue: number | null = !isNaN(value) ? parseFloat(value) : null
		if (intValue === null) {
			errors.push(key)
		}
	}

	checkFieldStringNotEmpty = <K extends keyof State>(key: K, errors: Array<string>) => {
		const value: any = this.state[key]
		const stringValue: string | null = typeof value === "string" ? value : null
		if (!stringValue || stringValue.length === 0) {
			errors.push(key)
		}

	}

	handleCopy = () => {
		//let now = moment().format('LLLL');
		//const m: moment.Moment = moment().format('LLLL')
		this.setState({
			openCopyCampaignDialog: true
		})
	}

	// onFileChange = (e: any) => {
	// 	if (e.target.files.length > 0) {
	// 		var url = URL.createObjectURL(e.target.files[0]);
	// 		var img = new Image();
	// 		img.onload = function() {
	// 			console.log(`width: ${img.width}, height: ${img.height}`)   
	// 		}
	// 		img.src = url;
	// 		this.setState({
	// 			image_url: url
	// 		})
	// 	}
	// }

	// uploadFile = () => {
	// 	// const { image } = this.state;
	// 	// if (image) {
	// 	// 	const name = image.name;
	// 	// 	const uploadTask = storage.ref(`images/${name}`).put(image);
	// 	// 	uploadTask.on('state_changed', this.uploadProgress, this.uploadError, this.uploadComplete)
	// 	// }
	// }

	uploadImageFile = () => {
		const { imageFile, provider, pid } = this.state;
		if (imageFile) {
			const fileExtension = imageFile.name.split('.').pop()
			const name = `${provider}_${pid}_main.${fileExtension}`
			const uploadTask = storage.ref(name).put(imageFile);
			uploadTask.on('state_changed', this.uploadImageProgress, this.uploadImageError, this.uploadImageComplete)
		}
	}

	uploadImageProgress = (snapshot: any) => {

	}

	uploadImageError = (error: any) => {
		this.setState({inSavingState: false})
		this.props.enqueueSnackbar('Failed to download image!', { variant: 'error' });
		console.log(error);
	}

	uploadImageComplete = () => {
		const { imageFile, provider, pid } = this.state;
		if (imageFile) {
			const fileExtension = imageFile.name.split('.').pop()
			const name = `${provider}_${pid}_main.${fileExtension}`
			storage.ref(name).getDownloadURL().then((url: string) => {
				this.setState({
					image_url: url
				}, () => this.saveCampaign())
			})
		}
	}

	onImageDrop = (files: Array<File>) => {
		var url = URL.createObjectURL(files[0]);
		var img = new Image();
		img.onload = () => { 
			this.setState({
				image_url: url,
				imageFile: files[0],
				image_width: img.width,
				image_height: img.height
			}) 
		}
		img.src = url;
	}
	
	handleFieldChange = (event: any) => {
    this.setState({ [event.target.name]: event.target.value });
	};
	
	handleSwitchVisible = () => {
    this.setState((prevState: State) => ({ visible: !prevState.visible }));
	};
	
	handleSwitchSale = () => {
    this.setState((prevState: State) => ({ sale: !prevState.sale }));
	};
	
	handleSwitchOptimized = () => {
    this.setState((prevState: State) => ({ optimized: !prevState.optimized }));
	};
	
	handleAcceptCopy  = () => {
		this.setState({
			id: undefined,
			provider: 'copy',
			openCopyCampaignDialog: false
		})
		this.props.enqueueSnackbar('Copy created!', { variant: 'success' });
	}

	handleDateChange = (date: Date) => {
    this.setState({
			selectedDate: date,
			expiry_date: date.getTime()
		});
  }

	render() {
		const { classes } = this.props;
		const {
			selectedDate,
			openCopyCampaignDialog,
			id,
			title,
			details,
			image_url,
			type,
			pid,
			provider,
			image_height,
			image_width,
			visible,
			optimized,
			sale,
			native_link,
			preview,
			mobile_link,
			banner,
			social_link,
			price,
			discount,
			discount_unit,
			callback_code,
			coupon,
			rate,
			rate_unit,
			like_counter,
			daily_cap,
			daily_cap_unit,
			email,
			email_link,
			epc,
			errors,
			imageFile
		} = this.state
    return (
      <div className={classes.root}>
        <Dialog
          fullScreen
          open={this.props.open}
          onClose={this.props.onClose}
          TransitionComponent={Transition}
        >
					<div>
						<AppBar position="static" className={classes.appBar}>
							<Toolbar>
								<IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
									<CloseIcon />
								</IconButton>
								<Typography className={classes.flex} variant="h6" color="inherit">
									{id ? 'Edit Campaign' : 'Create Campaign'}
								</Typography>
								{id && like_counter && like_counter > 0 ? (
									<Badge className={classes.badgeFav} badgeContent={like_counter} max={999} color="secondary">
										<FavoriteIcon  />
									</Badge>
								) : null}
								{
									id && (
										<Button color="inherit" onClick={this.handleCopy}>
											COPY
										</Button>
									)
								}
								<Button color="inherit" onClick={this.handleSave}>
									SAVE
								</Button>
							</Toolbar>
						</AppBar>
						
							<List className={classes.list}>
								<ListItem>
									<form className={classes.formContainer} noValidate autoComplete="off">
										<TextField
											id="field-name"
											label="Title"
											name="title"
											className={classes.textField40}
											value={title}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('title')}
										/>
										<TextField
											id="field-details"
											label="Details"
											name="details"
											className={classes.textField50}
											value={details}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('details')}
										/>
										<br/>
										<TextField
											id="field-type"
											label="Type"
											name="type"
											className={classes.textField20}
											value={type}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('type')}
										/>
										<TextField
											id="field-pid"
											label="Pid"
											name="pid"
											className={classes.textField20}
											value={pid}
											onChange={this.handleFieldChange}
											margin="normal"
											type="number"
											required={true}
											error={errors && errors.includes('pid')}
										/>
										<TextField
											id="field-provider"
											label="Provider"
											name="provider"
											className={classes.textField20}
											value={provider}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('provider')}
										/>
										<br/>
										<TextField
											id="field-price"
											label="Price"
											name="price"
											className={classes.textField20}
											value={price}
											onChange={this.handleFieldChange}
											margin="normal"
											type="number"
											required={true}
											error={errors && errors.includes('price')}
										/>
										<TextField
											id="field-discount"
											label="Discount"
											name="discount"
											className={classes.textField20}
											value={discount}
											onChange={this.handleFieldChange}
											margin="normal"
											type="number"
											required={true}
											error={errors && errors.includes('discount')}
										/>
										<TextField
											id="field-discount_unit"
											label="Discount unit"
											name="discount_unit"
											className={classes.textField20}
											value={discount_unit}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('discount_unit')}
										/>
										<br/>
										<TextField
											id="field-rate"
											label="Rate"
											name="rate"
											className={classes.textField20}
											value={rate}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('rate')}
										/>
										<TextField
											id="field-rate_unit"
											label="Rate unit"
											name="rate_unit"
											className={classes.textField20}
											value={rate_unit}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('rate_unit')}
										/>
										<TextField
											id="field-daily_cap"
											label="Daily cap"
											name="daily_cap"
											className={classes.textField20}
											value={daily_cap}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											type="number"
											error={errors && errors.includes('daily_cap')}
										/>
										<TextField
											id="field-daily_cap_unit"
											label="Daily cap unit"
											name="daily_cap_unit"
											className={classes.textField20}
											value={daily_cap_unit}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											error={errors && errors.includes('daily_cap_unit')}
										/>
										<TextField
											id="field-epc"
											label="Epc"
											name="epc"
											className={classes.textField20}
											value={epc}
											onChange={this.handleFieldChange}
											margin="normal"
											required={true}
											type="number"
											error={errors && errors.includes('epc')}
										/>
										<DatePicker
											margin="normal"
											label="Expiry date"
											value={selectedDate}
											onChange={this.handleDateChange}
										/>
										<div style={{flexDirection: "row", display: 'flex',}}>
											<FormControlLabel
												className={classes.switchControl}
												control={
													<Checkbox color="primary" name="visible" checked={visible} onChange={this.handleSwitchVisible} />
												}
												label="Visible"
											/>
											<FormControlLabel
												className={classes.switchControl}
												control={
													<Checkbox color="primary" name="sale" checked={sale} onChange={this.handleSwitchSale} />
												}
												label="Sale"
											/>
											<FormControlLabel
												className={classes.switchControl}
												control={
													<Checkbox color="primary" name="optimized" checked={optimized} onChange={this.handleSwitchOptimized} />
												}
												label="Optimized"
											/>
										</div>
										<Dropzone
											onDrop={this.onImageDrop}
											accept="image/*"
											minSize={300}
											multiple={false}>
												{({getRootProps, getInputProps}) => {
													return (
														<div
															className={classes.dropZone}
															{...getRootProps()}
														>
															<input {...getInputProps()} />
															{/* {errors && errors.includes('image_url') ? (
																<Typography 
																	className={classes.flex}
																	color={errors && errors.includes('image_url') ? 'inherit' : 'error'}>
																	Select image
																</Typography>
																<p color='red'>Error, please provide image </p>
															): (
																<p>  Drop Zone </p>
															)} */}
															<Typography
																color={!imageFile && errors && errors.includes('image_url') ? 'error' : 'inherit'}>
																Selected image:  {`width: ${image_width || 0}, heigth: ${image_height || 0}`}
															</Typography>
															{image_url && (<img className={classes.image} src={image_url} />)}
														</div>
													)
											}}
										</Dropzone>
										<br/>
										<TextField
											id="field-coupon"
											label="Coupon"
											name="coupon"
											className={classes.textField80}
											value={coupon}
											onChange={this.handleFieldChange}
											margin="normal"
										/>
										<br/>
										<TextField
											id="field-email"
											label="Email"
											name="email"
											className={classes.textField80}
											value={email}
											multiline
											onChange={this.handleFieldChange}
											margin="normal"
										/>
										<br/>
										<FormGroup row className={classes.formContainer}>
											<TextField
												id="field-native_link"
												label="Native link"
												name="native_link"
												className={classes.textField80}
												value={native_link}
												onChange={this.handleFieldChange}
												margin="normal"
												required={true}
												error={errors && errors.includes('native_link')}
											/>
											<TextField
												id="field-preview"
												label="Preview"
												name="preview"
												className={classes.textField80}
												value={preview}
												onChange={this.handleFieldChange}
												margin="normal"
											/>
											<TextField
												id="field-mobile_link"
												label="Mobile link"
												name="mobile_link"
												className={classes.textField80}
												value={mobile_link}
												onChange={this.handleFieldChange}
												margin="normal"
											/>
											<TextField
												id="field-social_link"
												label="Social link"
												name="social_link"
												className={classes.textField80}
												value={social_link}
												onChange={this.handleFieldChange}
												margin="normal"
											/>
											<TextField
												id="field-banner"
												label="Banner"
												name="social_link"
												className={classes.textField80}
												value={banner}
												onChange={this.handleFieldChange}
												margin="normal"
											/>
											<TextField
												id="field-email_link"
												label="Email link"
												name="email_link"
												className={classes.textField80}
												value={email_link}
												onChange={this.handleFieldChange}
												margin="normal"
											/>
											<TextField
												id="field-email_callback_code"
												label="Callback code"
												name="callback_code"
												className={classes.textField80}
												value={callback_code}
												onChange={this.handleFieldChange}
												margin="normal"
											/>
										</FormGroup>

										
									</form>
								</ListItem>
		
								<ListItem button>
									
								</ListItem>
							</List>
					{/* <Dropzone
						multiple={false}
						accept="image/*"
						onDrop={this.onImageDrop}>
						{(x: any) => (
							<p>Drop an image or click to 
								
								select a file to upload.</p>
						)}
					</Dropzone> */}

					
					{/* <input type="file" onChange={this.onFileChange} />
					<Button variant="contained" color="primary" onClick={this.uploadFile}>
						Upload
					</Button>
					<img className={classes.image} src={imagePreview} /> */}
					</div>
        </Dialog>
				{openCopyCampaignDialog && (<CopyCampaignDialog 
					open={openCopyCampaignDialog}
					onClose={() => {
						this.setState({openCopyCampaignDialog: false})
					}}
					onAccept={this.handleAcceptCopy}
				/>)}
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(EditCampaignDialog));
