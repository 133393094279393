import React, { Component } from 'react';
import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import {
  Grid,
  Form,
  Segment,
  Button,
  Header,
  Message,
  Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import firebase from "../../data/firebase"
import Background from '../../images/bg.png';
import GooglePlayButton from '../../images/btn.googleplay.png';
import AppStoreButton from '../../images/btn.appstore.png';
import HeaderImage from '../../images/splash_header.png';
import FooterImage from '../../images/splash_footer.png';
import LogoImage from '../../images/splash_logo.png';
// import AppStoreButton from '../../images/.png';


const styles: StyleRulesCallback = (theme) => ({
  center: {
		display: 'flex', 
		justifyContent: 'center'
  },
  bg: {
    backgroundImage: `url(${Background})`
  }
});

interface Props {
	classes: any
}

interface Props {
	classes: any
}

interface State {
	email: string,
	password: string,
	errors: Array<any>,
	loading: boolean
}

class WelcomePage extends React.Component<Props, State> {

	state: Readonly<State> = {
		email: "",
    password: "",
    errors: [],
    loading: false
	};

  render() {
    const { email, password, errors, loading } = this.state;

    return (
      <Grid style={{
        // backgroundImage: `url(${Background})`,
        backgroundColor: '#9CCB3B',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        // position: 'relative',
        // top: 0,
        // boottom: 0,
        height: '100vh'
      }} textAlign="center" verticalAlign="middle" className="app">
        <Grid.Column style={{ maxWidth: 450 }}>
          <img style={{paddingBottom: 40}} src={HeaderImage} alt="HeaderImage" />
          <img style={{paddingBottom: 40}} src={LogoImage} alt="LogoImage" />
          <Grid.Row style={{ maxWidth: 450 }}>
            <a target="_blank" href="https://itunes.apple.com/app/laube/id1456482956">
              <img src={AppStoreButton} alt="AppStoreButton" />
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.softintouch.adplick">
              <img src={GooglePlayButton} alt="GooglePlayButton" />
            </a>
          </Grid.Row>
          {/* <img src={FooterImage} alt="FooterImage" /> */}
        </Grid.Column>
      </Grid>
    );
  }
}

export default withStyles(styles)(WelcomePage);