import React from 'react';
import ReactDOM from 'react-dom';
import Home from "./pages/home/Home";
import Login from "./pages/auth/Login";
import Spinner from "./components/spinner/Spinner";
import firebase, { auth } from "./data/firebase";
import * as serviceWorker from './serviceWorker';
import "semantic-ui-css/semantic.min.css";
import * as Redux from 'redux'
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
	RouteComponentProps
} from "react-router-dom";

import { createStore } from "redux";
import { Provider, connect } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { setUser, clearUser } from "./data/user/actions";
import Signup from './pages/auth/Register';
import AppState from './data/redux/state';
// import store from './data/redux/storage';
import rootReducer from './data/redux/reducer';
import store from './data/redux/store';
import User from './data/user/model';
import WelcomePage from './pages/welcome/WelcomePage';


interface StateProps {
	isLoading: boolean
	
}
     
interface DispatchProps {
	setUser: (user: User) => void,
	clearUser: () => void
}

interface OwnProps extends RouteComponentProps {
	isLoading: boolean
	setUser: (user: User) => void,
	clearUser: () => void
}

interface PathProps {
	// ...
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps<PathProps>

interface IProps{}    
interface IState {}

class Root extends React.Component<Props> {

	componentDidMount() {
    console.log(this.props.history.location)
		auth.onAuthStateChanged(user => {
      if (user) {
        // console.log(user);
        this.props.setUser(user);
        if (this.props.history.location.pathname !== '/') {
          this.props.history.push("/admin");
        }
      } else {
        if (this.props.history.location.pathname !== '/') {
          this.props.history.push("/login");
        }
        this.props.clearUser();
      }
    });
	}

	render() {
    return this.props.isLoading ? (
      <Spinner />
    ) : (
      <Switch>
        <Route exact path="/" component={WelcomePage} />
        <Route path="/admin" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Signup} />
      </Switch>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.userData.isLoading
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
	setUser: (user: User) => dispatch(setUser(user)),
	clearUser: () => dispatch(clearUser())
});

const RootWithAuth = withRouter<any>(connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(Root));

ReactDOM.render(
  <Provider store={store}>
    <Router>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<SnackbarProvider maxSnack={3}>
					<RootWithAuth />
				</SnackbarProvider>
			</MuiPickersUtilsProvider>
		</Router>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();


