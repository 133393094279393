import { combineEpics, Epic, ofType, createEpicMiddleware, Options } from 'redux-observable';
import { tap, ignoreElements, filter } from 'rxjs/operators';
import { Action } from './actions';
import AppState from './state';
import { Services } from '../services/types';

const logAddAction:  Epic<Action, Action, AppState, Services> = (
  action$,
  store,
  { logger }
) =>
  action$.pipe(
		ofType('PING'),
    tap(action => {
      logger.log(
        `action type must be equal: ${action.type}`
      );
    }),
    ignoreElements()
  );

export default combineEpics(logAddAction);