import React from 'react';

import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Loader, Dimmer } from "semantic-ui-react";

const styles: StyleRulesCallback = (theme) => ({
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

const Spinner = ({ classes }: { classes: any }) => (
	<Dimmer active>
		<CircularProgress className={classes.progress} color="secondary"/>
	</Dimmer>
)

export default withStyles(styles)(Spinner)