import { ACTION_CLEAR_USER, ACTION_SET_USER } from './actions'
import UserState, { initialUserState } from './state';
import { Action } from '../redux/actions';


const userReducer = (state = initialUserState, action: Action): UserState => {
  switch (action.type) {
    case ACTION_SET_USER:
      return {
        currentUser: action.payload.currentUser,
        isLoading: false
      };
    case ACTION_CLEAR_USER:
      return {
        ...initialUserState,
        isLoading: false
      };
    default:
      return state;
  }
}

export default userReducer