import User from './model'

export const ACTION_SET_USER = "ACTION_SET_USER";
export const ACTION_CLEAR_USER = "ACTION_CLEAR_USER";

export const setUser = (user: User) => {
  return {
    type: ACTION_SET_USER,
    payload: {
      currentUser: user
    }
  };
};

export const clearUser = () => {
  return {
    type: ACTION_CLEAR_USER
  };
};
