import 'firebase';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const config = {
	apiKey: "AIzaSyASXYngPozt7K8H8o4a3ff9wmXQKwYclWI",
	authDomain: "adplick.firebaseapp.com",
	databaseURL: "https://adplick.firebaseio.com",
	projectId: "adplick",
	storageBucket: "adplick.appspot.com",
	messagingSenderId: "863955358378"
};

firebase.initializeApp(config);

const storage = firebase.storage();
const db = firebase.firestore();
const auth = firebase.auth();

// export default app;
 
export {
	firebase as default,
	storage,
	db,
	auth
}