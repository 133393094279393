import React, { Component } from 'react';
import * as Redux from 'redux'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import firebase from '../../data/firebase';
import path from 'path'
import AppState from '../../data/redux/state';
import { connect } from 'react-redux';
import User from '../../data/user/model';


const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles: StyleRulesCallback = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

// interface Props {
// 	classes: any,
// 	onDrawerToggle: () => void,
// 	currentUser: User,
// }

interface State {
	anchorEl: any,
}

interface OwnProps {
	classes: any,
	onDrawerToggle: () => void
}

interface StateProps {
	currentUser: User
}
     
interface DispatchProps {
}

type Props = StateProps & DispatchProps & OwnProps

class Header extends Component<Props, State> {
	state: Readonly<State> = {
		anchorEl: null
	}

	handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
	};
	
	handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => console.log("signed out!"));
	};
	
	render() {
		const { anchorEl } = this.state
		const { classes, onDrawerToggle, currentUser } = this.props;
		
		return (
			<React.Fragment>
				<AppBar color="primary" position="sticky" elevation={0}>
					<Toolbar>
						<Grid container spacing={8} alignItems="center">
							<Hidden smUp>
								<Grid item>
									<IconButton
										color="inherit"
										aria-label="Open drawer"
										onClick={onDrawerToggle}
										className={classes.menuButton}
									>
										<MenuIcon />
									</IconButton>
								</Grid>
							</Hidden>
							<Grid item xs />
							{/* <Grid item>
								<Typography className={classes.link} component="a">
									Go to docs
								</Typography>
							</Grid> */}
							{/* <Grid item>
								<Tooltip title="Alerts • No alters">
									<IconButton color="inherit">
										<NotificationsIcon />
									</IconButton>
								</Tooltip>
							</Grid> */}
							<Grid item>
								<IconButton color="inherit" className={classes.iconButtonAvatar} onClick={this.handleClick}>
									<Avatar className={classes.avatar} src={currentUser!.photoURL || ''} />
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={anchorEl}
									open={Boolean(anchorEl)}
									onClose={this.handleClose}
								>
									<MenuItem onClick={this.handleClose}>Profile</MenuItem>
									<MenuItem onClick={this.handleClose}>My account</MenuItem>
									<MenuItem onClick={this.handleSignout}>Logout</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
				<AppBar
					component="div"
					className={classes.secondaryBar}
					color="primary"
					position="static"
					elevation={0}
				>
					<Toolbar>
						<Grid container alignItems="center" spacing={8}>
							<Grid item xs>
								<Typography color="inherit" variant="h5">
									Tables:
								</Typography>
							</Grid>
							{/* <Grid item>
								<Button className={classes.button} variant="outlined" color="inherit" size="small">
									Web setup
								</Button>
							</Grid> */}
							{/* <Grid item>
								<Tooltip title="Help">
									<IconButton color="inherit">
										<HelpIcon />
									</IconButton>
								</Tooltip>
							</Grid> */}
						</Grid>
					</Toolbar>
				</AppBar>
				<AppBar
					component="div"
					className={classes.secondaryBar}
					color="primary"
					position="static"
					elevation={0}
				>
					<Tabs value={0} textColor="inherit">
						<Tab textColor="inherit" label="Campaigns" />
					</Tabs>
				</AppBar>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
  currentUser: state.userData.currentUser
});

// const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
// });

// const RootWithAuth = connect(mapStateToProps)(Header));

// connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(Header);

export default withStyles(styles)(connect(mapStateToProps)(Header));