export default interface Campaign {
	id: string,
	title: string,
	details: string,
	image_url: string,
	type: string,
	banner: string,
	callback_code: string,
	categories: Array<string>,
	coupon: string,
	daily_cap: number,
	daily_cap_unit: string,
	discount: number,
	discount_unit: string,
	email: string,
	email_link: string,
	epc: number,
	expiry_date: number,
	image_height: number,
	image_width: number,
	like_counter: number,
	mobile_link: string,
	native_link: string,
	optimized: boolean,
	pid: number,
	provider: string,
	preview: string,
	price: number,
	rate: number,
	rate_unit: string,
	sale: boolean,
	social_link: string,
	visible: boolean
}

export interface CampaignOptional {
	id?: string,
	title?: string,
	details?: string,
	image_url?: string,
	type?: string,
	banner?: string,
	callback_code?: string,
	categories?: Array<string>,
	coupon?: string,
	daily_cap?: number,
	daily_cap_unit?: string,
	discount?: number,
	discount_unit?: string,
	email?: string,
	email_link?: string,
	epc?: number,
	expiry_date?: number,
	image_height?: number,
	image_width?: number,
	like_counter?: number,
	mobile_link?: string,
	native_link?: string,
	optimized?: boolean,
	pid?: number,
	provider?: string,
	preview?: string,
	price?: number,
	rate?: number,
	rate_unit?: string,
	sale?: boolean,
	social_link?: string,
	visible?: boolean
}

export const newCampaign: CampaignOptional = {
	id: undefined,
	title: '',
	details: '',
	image_url: undefined,
	image_height: undefined,
	image_width: undefined,
	pid: undefined,
	type: 'gift',
	banner: '',
	callback_code: '',
	categories: [],
	coupon: '',
	daily_cap: 0,
	daily_cap_unit: 'none',
	discount: 0,
	discount_unit: 'percent',
	email: '',
	email_link: '',
	epc: 0,
	expiry_date: 0,
	like_counter: 0,
	mobile_link: '',
	native_link: '',
	optimized: false,
	provider: 'mb',
	preview: '',
	price: 0,
	rate: 0,
	rate_unit: 'lead',
	sale: false,
	social_link: '',
	visible: true
}