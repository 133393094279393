import React, { Fragment } from 'react';

import {
	AppBar,
	Toolbar,
	Grid,
	Typography,
	Button,
	TextField,
	Tooltip,
	IconButton,
	Paper,
	List,
	ListItem,
	Avatar,
	ListItemText,
	Fab
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';


import { withStyles, StyleRulesCallback } from '@material-ui/core/styles';
import firebase from '../../data/firebase';
import Campaign, { newCampaign, CampaignOptional } from '../../data/campaign/model';
import EditCampaignDialog from '../campaign/EditCampaignDialog';
import DeleteCampaignDialog from '../campaign/DeleteCampaignDialog';
import { withSnackbar, InjectedNotistackProps } from 'notistack';

const styles: StyleRulesCallback = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing.unit,
  },
  contentWrapper: {
    margin: '10px 16px',
	},
	list: {
    marginBottom: theme.spacing.unit * 2,
  },
});

interface Props extends InjectedNotistackProps {
	classes: any
}

interface State {
	campaignRef: firebase.firestore.CollectionReference
	campaigns: Array<Campaign>,
	search: string,
	openEditCampaignDialog: boolean,
	openDeleteCampaignDialog: boolean,
	currentCampaign?: CampaignOptional
}

class Content extends React.Component<Props, State> {
	state: Readonly<State> = {
		campaignRef: firebase.firestore().collection("campaigns"),
		campaigns: [],
		search: '',
		openEditCampaignDialog: false,
		currentCampaign: undefined,
		openDeleteCampaignDialog: false
	}

	unsubscribe?: () => void

	componentDidMount() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    this.unsubscribe = this.state.campaignRef.onSnapshot((snap: any) => {
			const loadedCampaigns: Array<any> = [];
			snap.forEach((doc: any) => {
				loadedCampaigns.push({...doc.data(), id: doc.id});
			});
			// loadedCampaigns.push(snap.docs.data());
			console.log('__loadedCampaigns', loadedCampaigns)
      this.setState({ campaigns: loadedCampaigns }, () => {});
    });
  };

  removeListeners = () => {
		if (this.unsubscribe) {
			this.unsubscribe()
			this.unsubscribe = undefined
		}
	};
	
	handleChangeSearch = (event: any) => {
    this.setState({ search: event.target.value });
	};
	
	filteredCampaigns = (): Array<Campaign> => {
		const { campaigns, search } = this.state;
		if (search.length > 0) {
			const searchLowerCase = search.toLowerCase()
			return campaigns.filter((c) => {
				const key = `#${c.provider}${c.pid}`
				return (
					c.title.toLowerCase().includes(searchLowerCase) ||
					key.toLowerCase().includes(searchLowerCase)
				)
			})
		} else {
			return campaigns
		}
	}

	handleCloseEdit = () =>  {
		this.setState({
			openEditCampaignDialog: false,
		})
	}

	handleAddCampaign = () => {
		this.setState({
			openEditCampaignDialog: true,
			currentCampaign: newCampaign
		})
	}

	handleEditCampaign = (campaign: Campaign) => {
		this.setState({
			openEditCampaignDialog: true,
			currentCampaign: campaign
		})
	}

	handleOpenDeleteDialog = (campaign: Campaign) => {
		this.setState({
			openDeleteCampaignDialog: true,
			currentCampaign: campaign
		})
	}

	handleCloseDeleteDialog = () => {
		this.setState({
			openDeleteCampaignDialog: false,
			currentCampaign: undefined
		})	
	}

	handleAcceptDeleteDialog = () => {
		const { currentCampaign, campaignRef } = this.state
		if (currentCampaign && currentCampaign.id) {
			console.log('delete')
			campaignRef.doc(currentCampaign.id).delete()
				.then(() => {
					this.props.enqueueSnackbar('Campaign deleted!', { variant: 'success' });
				})
				.catch((error: any) => {
					this.props.enqueueSnackbar('Campaign failed!', { variant: 'error' });
				})
		}
		this.setState({
			openDeleteCampaignDialog: false,
			currentCampaign: undefined
		})	
	}

	render() {
		const { classes } = this.props;
		const {
			search,
			openEditCampaignDialog,
			openDeleteCampaignDialog
		} = this.state;
		const filteredCampaigns = this.filteredCampaigns()

    return (
			<Paper className={classes.paper}>
      <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
        <Toolbar>
          <Grid container spacing={16} alignItems="center">
            <Grid item>
              <SearchIcon className={classes.block} color="inherit" />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                placeholder="Search by name and details"
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
								}}
								value={search}
								onChange={this.handleChangeSearch}
              />
            </Grid>
            <Grid item>
							<Button
								variant="contained"
								color="primary"
								className={classes.addUser}
								onClick={this.handleAddCampaign}
								>
                Add campaign
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.contentWrapper}>
				{filteredCampaigns.length > 0 ? (
					<List className={classes.list}>
						{filteredCampaigns.map((campaign) => {
							const { id, title, pid, provider, details, image_url } = campaign
							return (
								<Fragment key={id}>
									<ListItem button>
										<Avatar alt="Profile Picture" src={image_url} />
										<ListItemText primary={`#${provider}${pid} - ${title}`} secondary={details} />
										<Tooltip 
											title="Edit"
											enterDelay={1000}
											leaveTouchDelay={1}>
											<IconButton 
												color="primary"
												aria-label="Edit"
												className={classes.margin}
												onClick={() => this.handleEditCampaign(campaign)}>
												<EditIcon fontSize="small" />
											</IconButton>
										</Tooltip>
										<Tooltip
											title="Delete"
											enterDelay={1000}
											leaveTouchDelay={1}>
											<IconButton
												aria-label="Delete" 
												className={classes.margin}
												onClick={() => this.handleOpenDeleteDialog(campaign)}
												>
												<DeleteIcon fontSize="small" />
											</IconButton>
										</Tooltip>
										
									</ListItem>
								</Fragment>
							)
						}
						)}
					</List>
				): (
					<Typography color="textSecondary" align="center">
          	No data
        	</Typography>
				)}
        {openEditCampaignDialog && (<EditCampaignDialog 
					open={openEditCampaignDialog}
					onClose={this.handleCloseEdit}
					campaign={this.state.currentCampaign}
				/>)}
				{openDeleteCampaignDialog && (<DeleteCampaignDialog 
					open={openDeleteCampaignDialog}
					onClose={this.handleCloseDeleteDialog}
					onDelete={this.handleAcceptDeleteDialog}
				/>)}
				
      </div>
    </Paper>
		);
	}
}

export default withStyles(styles)(withSnackbar(Content));